import {getCookie, setCookie} from "./cookies";
import {ajax} from "./ajax";

function sendLinkEventToGA4(category, action, label, link) {
    var event = category.toLowerCase();
    if (event === 'header' || event === 'footer') {
        dataLayer.push({
            event: category.toLowerCase(),
            link_id: action,
            link_text: label,
            link_url: link.attr('href')
        });
    }
}

export function sendPopupEvent(eventName, contentType, method) {
    dataLayer.push({
        event: eventName,
        method: method,
        content_type: contentType
    });
}

export function trackLinkEvent(category, action, label, link, event) {
    let target = (!link.attr('target') ? "_self" : link.attr('target'));
    if (event.which != 1 || event.ctrlKey) {
        target = "_blank";
    }
    sendLinkEventToGA4(category, action, label, link)
    trackEvent(category, action, label);
    if (event.which == 3) {return;}
    openLink(link, target);
}
export function trackEvent(category, action, label, callback) {
    dataLayer.push({
        event: category.toLowerCase(),
        method: action || '',
        content_type: label || ''
    });

    if (false && gaLoaded) {
        ga('send', 'event', category, action, label, {
                hitCallback: function() {
                    typeof callback === 'function' && callback();
                }
            }
        );
    } else {
        typeof callback === 'function' && callback();
    }
}
export function openLink(link, target) {
    window.open(link.attr('href'), target);
}
export function ecAddProduct(id, name, price, quantity, color, size, orientation) {
    return;
    let productObject = {
        'id': id,
        'name': name
    };
    if (price) {
        productObject['price'] = price;
    }
    if (quantity) {
        productObject['quantity'] = quantity;
    }
    if (color) {
        productObject['variant'] = color;
    }
    if (size) {
        productObject['dimension1'] = size;
    }
    if (orientation) {
        if (orientation == 'reversed') {
            orientation = 'mirrored';
        }
        productObject['dimension2'] = orientation;
    }
    window.ga('ec:addProduct', productObject);
}
export function ecSetAction(action, params) {
    return;
    if (params) {
        window.ga('ec:setAction', action, params);
    } else {
        window.ga('ec:setAction', action);
    }
}
export function ecSendData(id, name, price, quantity, color, size, orientation, action, params) {
    if (id) {
        ecAddProduct(id, name, price, quantity, color, size, orientation);
        ecSetAction(action, params);
        if (dataLayerAddProduct && (action === 'add' || action === 'remove')) {
            let item = {
                'reference': id,
                'name': name,
                'brand': null,
                'currency': null,
                'price': price,
                'quantity': quantity,
                'color': color,
            };
            dataLayerAddProduct(item, (action === 'remove'));
        }
    }
}
export function ecAddPromo(id, name, creative, position) {
    return;
    window.ga('ec:addPromo', {
        'id': id,
        'name': name,
        'creative': creative,
        'position': position
    });
}
export function ecPromoClick(id, name, creative, position) {
    if (id) {
        ecAddPromo(id, name, creative, position);
        ecSetAction('promo_click');
    }
}
export function saveAffiliation() {
    let currentUrl = window.location.href;
    let name = "utm_source";
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(currentUrl);
    if (!results) return null;
    if (!results[2]) return '';
    let value = decodeURIComponent(results[2].replace(/\+/g, " "));
    setCookie('affiliation', value, 30);
}

export function sendFacebookConversionsEvent(event, payload)
{
    ajax.post(
        '/_api/fb/conversions_event',
        {
            event: event,
            payload: payload
        }
    );
}

function dataLayerAddProduct(item, remove) {
    remove = remove || false;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({ dynamicRemarketingData: null });
    dataLayer.push({
        event: !remove ? "add_to_cart" : "remove_from_cart",
        ecommerce: {
            items: [
                {
                    item_id: item.reference,
                    item_name: item.name,
                    item_brand: item.brand || null,
                    currency: item.currency,
                    //discount: 2.22,
                    //item_variant: item.color,
                    price: item.price,
                    quantity: item.quantity < 0 ? item.quantity * -1 : item.quantity
                }
            ]
        },
        dynamicRemarketingData: {
            value: item.price,
            items: [
                {
                    id: item.reference,
                    google_business_vertical: 'retail'
                }
            ]
        }
    });
}