import {ecSendData, trackEvent, sendFacebookConversionsEvent} from "../../library/analytics";
import {sendFloodLightEvent, showShoppingCart} from "./product-shopping-cart";
import {hidePopup} from "./slide-up-popup";
import {popupInfoDialog} from "../../library/info-dialog";
import {waitingDialog} from "../../library/waiting-dialog";
import {blockedAllCookies, getCookie} from "../../library/cookies";
import {productAddToCart} from "../../website/webpack/modules/algolia-insights";

export function addToCartFunctions(c, eventPage) {
    this.container = c;
    this.eventPage = eventPage || 'ProductList';
    this.sizeAJAX;
    this.priceAJAX;

    let quantityControls = '';

    this.isMobileDevice = function() {
        if (navigator.userAgent.indexOf('MSIE') !==-1 || navigator.appVersion.indexOf('Trident/') > -1) {
            return false;
        }
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };

    this.displaySelectedSize = function(updatePrice = true) {
        if (typeof this.sizeAJAX == 'object') {
            this.sizeAJAX.abort();
        }

        let selectedSize = '';
        let customWidth = this.container.find('#custom-width');
        let customHeight = this.container.find('#custom-height');

        if (updatePrice) {
            this.container.find("#add-to-cart-size").data("ready", false);
        }

        if (customWidth.length && customWidth.val() && customHeight.length && customHeight.val()) {
            let wrongSizeAlertMin = this.container.find('#wrong-size-alert-min');
            let wrongSizeAlertMax = this.container.find('#wrong-size-alert-max');
            let wrongSizeAlertMaxWidth = this.container.find("#wrong-size-alert-max-width");
            let wrongSizeAlertMaxHeight = this.container.find("#wrong-size-alert-max-height");
            let upperSizeAlert = this.container.find('#upper-size-alert');

            wrongSizeAlertMin.slideUp('fast');
            wrongSizeAlertMax.slideUp('fast');
            wrongSizeAlertMaxWidth.slideUp('fast');
            wrongSizeAlertMaxHeight.slideUp('fast');
            upperSizeAlert.slideUp('fast');

            if (customWidth.val() && customHeight.val()) {
                let widthMin = Number(customWidth.attr('data-min'));
                let heightMin = Number(customHeight.attr('data-min'));
                let widthMax = Number(customWidth.attr('data-max'));
                let heightMax = Number(customHeight.attr('data-max'));
                let errorSize = false;

                if (customWidth.val() < widthMin || customHeight.val() < heightMin) {
                    wrongSizeAlertMin.slideDown('fast');
                    errorSize = true;
                } else if (widthMax && heightMax && customWidth.val() > widthMax && customHeight.val() > heightMax) {
                    wrongSizeAlertMax.slideDown('fast');
                    errorSize = true;
                } else if(widthMax && customWidth.val() > widthMax) {
                    wrongSizeAlertMaxWidth.slideDown('fast');
                    errorSize = true;
                } else if (heightMax && customHeight.val() > heightMax){
                    wrongSizeAlertMaxHeight.slideDown('fast');
                    errorSize = true;
                } else {
                    selectedSize = customWidth.val() + ' x ' + customHeight.val();
                }

                let widthUpper = Number(customWidth.attr('data-upper'));
                let heightUpper = Number(customHeight.attr('data-upper'));
                if (customWidth.val() > widthUpper && customHeight.val() > heightUpper && !errorSize) {
                    upperSizeAlert.slideDown('fast');
                }
                this.changePreviewSize(selectedSize);
                if (updatePrice) {
                    this.updatePrice();
                }
            }

        } else {
            let productId = this.container.find('#productId').val();
            let persWidth = this.container.find('input[name="sizeWidthPers"]').val();
            let persWidthIsChecked = this.container.find('#rate-pers').is(':checked');
            let persWidthPopupIsChecked = this.container.find('#popup-rate-pers').is(':checked');
            let overSizeNotice = this.container.find('#product-oversize-notice');

            if (productId && (persWidthIsChecked || persWidthPopupIsChecked) && persWidth) {
                let scope = this;
                overSizeNotice.slideUp('fast');
                clearTimeout(this.typingTimer);
                scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').attr('disabled', 'disabled');
                this.typingTimer = setTimeout(function () {
                    if (typeof scope.sizeAJAX == 'object') {
                        scope.sizeAJAX.abort();
                    }
                    scope.sizeAJAX = $.post(
                        '/ajax/product/calculate-custom-height',
                        {
                            productId: productId,
                            width: persWidth
                        },
                        function (data) {
                            let hidePersonalizedPrice = false;

                            if (data.height) {
                                scope.container.find('#size-height-pers').html(data.height);
                                selectedSize = persWidth + scope.container.find('.pers-size-calculated').text();
                                scope.changePreviewSize(selectedSize);
                                if (data.error && data.error.length) {
                                    //overSizeNotice.html(data.error);
                                    //overSizeNotice.slideDown('fast');
                                    popupInfoDialog(data.error);
                                }
                            } else {
                                hidePersonalizedPrice = true;
                                scope.container.find('input[name="sizeWidthPers"]').val('');
                                scope.container.find('#size-height-pers').html(0);
                                scope.container.find('.pers-size-price').hide();

                                popupInfoDialog(data.error);
                            }

                            scope.updatePrice(hidePersonalizedPrice);
                            scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').removeAttr('disabled');
                        },
                        'json'
                    ).fail(function () {
                        scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').removeAttr('disabled');
                    });
                }, 700);
            } else {
                let selected = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-size"]:checked') : this.container.find('input[name="size"]:checked');
                let id = selected.attr('id');
                let sizeId = selected.data('sizeid');
                let width = selected.data('width') || null;
                let height = selected.data('height') || null;
                let device = selected.data('deviceid') || null;
                let composition = selected.data('compositionId') || null;

                this.container.find('#productSize').val(id);
                this.container.find('#productSize').data('sizeid', sizeId);
                this.container.find('#productSize').data('width', width);
                this.container.find('#productSize').data('height', height);
                this.container.find('#productSize').data('device', device);
                this.container.find('#productSize').data('composition', composition);

                selectedSize = selected.data('name');
                this.changePreviewSize(selectedSize);
                if (updatePrice) {
                    this.updatePrice();
                }
            }
        }
    };

    this.changePreviewSize = function(selectedSize) {
        if (selectedSize) {
            this.container.find('#preview-size').fadeOut('fast', function () {
                $(this).html(selectedSize).fadeIn().trigger('change');
            });
        }
    };

    this.updateSizesPrices = function () {
        if (typeof this.sizeAJAX == 'object') {
            this.sizeAJAX.abort();
        }

        let scope = this;
        let productId = this.container.find('#productId').val();
        let pack = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-pack"]:checked').val() : this.container.find('input[name="pack"]:checked').val();
        if (pack == 'custom') {
            pack = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-customPack"]').val() : this.container.find('input[name="customPack"]').val();
        }
        let color = null;
        if (this.container.find('input[name="color"]').length) {
            color = this.container.find('input[name="color"]').val();
        }
        let freeShippingFrom = false;
        let expressFreeShippingFrom = false;
        if ($('#freeShippingFrom').length && $('#freeShippingFrom').val()) {
            freeShippingFrom = $('#freeShippingFrom').val();
        }
        if ($('#expressFreeShippingFrom').length && $('#expressFreeShippingFrom').val()) {
            expressFreeShippingFrom = $('#expressFreeShippingFrom').val();
        }

        scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').attr('disabled', 'disabled');
        this.sizeAJAX = $.post(
            '/ajax/product/update-sizes-prices',
            {
                productId: productId,
                options: {
                    pack: pack,
                    color: color
                },
            },
            function (data) {
                $.each(data.prices, function(index, value) {
                    let input = scope.container.find('input[data-sizeid="'+value.id+'"]');
                    input.val(value.price);
                    let label = input.siblings('label');
                    label.find('.size-price').html(value.formattedPrice);
                    if (value.oldPrice) {
                        label.find('.size-old-price').html(value.formattedOldPrice);
                    }
                    label.find('.free-ship').remove();
                    if (expressFreeShippingFrom && expressFreeShippingFrom <= value.price) {
                        label.find('.size-offers').append(data.freeExpressShippingView);
                    } else if (freeShippingFrom && freeShippingFrom <= value.price) {
                        label.find('.size-offers').append(data.freeShippingView);
                    }
                });
                scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').removeAttr('disabled');
                scope.updatePrice();
            }
        ).fail(function () {
            scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').removeAttr('disabled');
        });
    }

    this.displaySelectedPack = function () {
        if (typeof this.sizeAJAX == 'object') {
            this.sizeAJAX.abort();
        }
        let selected = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-pack"]:checked') : this.container.find('input[name="pack"]:checked');
        let pack = selected.val();
        let selectedPack = selected.data('name');
        let scope = this;
        if (pack == 'custom') pack = this.container.find('input[name=customPack]').val();
        if (!pack) return;
        scope.changePreviewPack(selectedPack);
    };

    this.changePreviewPack = function(selectedPack) {
        if (selectedPack) {
            this.container.find('#preview-pack').fadeOut('fast', function () {
                $(this).html(selectedPack).fadeIn();
            });
        }
    };

    // TOCAR AQUI!
    this.updatePrice = function(hidePersonalizedPrice) {
        if (typeof this.priceAJAX == 'object') {
            this.priceAJAX.abort();
        }

        this.container.find("#add-to-cart-size").data("ready", false);

        let unitPrice = 0;
        let productId = this.container.find('#productId').val() || this.container.find('#productId').val();

        this.container.find('.btn-add-to-cart, .btn-modify-cart-item').attr('disabled', 'disabled');

        let scope = this;
        if (productId) {
            let ajaxPayload = {};
            let quantity = this.container.find('input[name="qty-display"]').val();
            let hasPersonalizedSize = 0;
            let hasBothSize = 0;
            let hasCatalogSize = 0;

            if (this.container.find('#t-shirt-product').length) {

                let multiProducts = [];

                this.container.find(".multi-product-options").each(function() {
                    let productIndex = $(this).data('productIndex').toString();
                    multiProducts.push(
                        {
                            width: 1,
                            height: 1,
                            options: {
                                model: $(this).find('#model'+productIndex).val(),
                                materialColor: $(this).find('#materialColor'+productIndex).val(),
                                color: $(this).find('#color'+productIndex).val(),
                                modelSize: $(this).find('#modelSize'+productIndex).val()
                            }
                        }
                    )
                })

                ajaxPayload = {
                    productId: productId,
                    quantity: quantity,
                    width: 1,
                    height: 1,
                    options: {
                        model: this.container.find('#model').val(),
                        materialColor: this.container.find('#materialColor').val(),
                        color: this.container.find('#color').val(),
                        modelSize: this.container.find('#modelSize').val(),
                    },
                    multiProducts: multiProducts
                };
            } else if (this.container.find('#blind-product').length) {
                let bothCustomWidth = this.container.find('#custom-width');
                let bothCustomHeight = this.container.find('#custom-height');
                let width = bothCustomWidth.val();
                let height = bothCustomHeight.val();
                if (!width || !height) {
                    scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').removeAttr('disabled');
                    return;
                }

                let tissue = this.container.find('#tissue').val();
                let mechanism = this.container.find('#mechanism').val();
                let drop = this.container.find('#drop').val();
                let supportColor = this.container.find('#support-color').val();

                ajaxPayload = {
                    productId: productId,
                    quantity: quantity,
                    width: width,
                    height: height,
                    options: {
                        tissue:             tissue,
                        mechanism:          mechanism,
                        drop:               drop,
                        supportColor:       supportColor
                    }
                };
            } else {
                let selectedSize = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-size"]:checked') : this.container.find('input[name="size"]:checked');
                let bothCustomWidth = this.container.find('#custom-width');
                let bothCustomHeight = this.container.find('#custom-height');
                let personalizedWidth = this.container.find('input[name="sizeWidthPers"]');
                let personalizedHeight = this.container.find('#size-height-pers');
                let pack = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-pack"]:checked').val() : this.container.find('input[name="pack"]:checked').val();
                if (pack == 'custom') {
                    pack = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-customPack"]').val() : this.container.find('input[name="customPack"]').val();
                }

                let width;
                let height;
                let device;
                let composition;

                if (selectedSize && selectedSize.val()) {
                    width = selectedSize.data('width');
                    height = selectedSize.data('height');
                    device = selectedSize.data('deviceid');
                    composition = selectedSize.data('compositionId');
                    hasCatalogSize = 1;
                } else if (bothCustomWidth.length && bothCustomHeight.length) {
                    width = bothCustomWidth.val();
                    height = bothCustomHeight.val();
                    hasBothSize = 1;

                } else if (personalizedWidth.length && personalizedHeight.length) {
                    width = personalizedWidth.val();
                    height = personalizedHeight.html();
                    hasPersonalizedSize = 1;
                }

                // Send options that affect to price
                let reversed = this.container.find('input[name="reversed"]').is(':checked');
                let color = null;
                if (this.container.find('input[name="color"]').length) {
                    color = this.container.find('input[name="color"]').val();
                }

                ajaxPayload = {
                    productId: productId,
                    width: width,
                    height: height,
                    device: device,
                    quantity: quantity,
                    pack: pack,
                    isCatalogSize: hasCatalogSize,
                    options: {
                        reversed: reversed,
                        color: color
                    },
                    composition: composition
                };
            }
            //let scope = this;
            this.priceAJAX = $.post(
                '/ajax/product/calculate-custom-price',
                ajaxPayload,
                function (data) {
                    if (data.price) {
                        unitPrice = data.price;

                        if (hasPersonalizedSize) {
                            let printPrice = unitPrice;
                            if (printPrice !== Math.floor(printPrice)) {
                                printPrice = unitPrice.toFixed(2);
                            }

                            scope.container.find('#persSizePrice').html(printPrice);
                            scope.container.find('.pers-size-price').show();
                        }
                    } else {
                        unitPrice = 0;

                        if (hasPersonalizedSize) {
                            scope.container.find('#persSizePrice').html(unitPrice);
                            scope.container.find('.pers-size-price').hide();
                        }
                        if (data.error) {
                            popupInfoDialog(data.error);
                        }
                    }

                    if (hidePersonalizedPrice) {
                        scope.container.find('.pers-size-price').hide();
                    }

                    scope.changePrice(unitPrice);
                    scope.container.find("#add-to-cart-size").data("ready", true);
                    scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').removeAttr('disabled');
                },
                'json'
            ).fail(function () {
                scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').removeAttr('disabled');
            });

        } else {
            this.changePrice(unitPrice);
            scope.container.find('.btn-add-to-cart, .btn-modify-cart-item').removeAttr('disabled');
        }
    };

    this.changePrice = function(unitPrice) {
        let quantity = this.container.find('input[name="qty-display"]').val();
        let priceTotal = parseFloat(unitPrice) * quantity;
        let complements = this.container.find('input[name="complement"]:checked');
        let crossSellerProducts = this.container.find('input[class="cross-selling-select-product"]:checked');
        let upSellingPersonalizedText = this.container.find('input[name="upSellingPersonalizedText"]:checked');

        this.container.find('input[name="controlQuantity"].active').each(function() {
            let controlQuantity = $(this).val();
            let price = $(this).data('price');
            let totalControlsPrice = controlQuantity * price;
            priceTotal = priceTotal + totalControlsPrice;
        });

        this.container.find('input[name="chargerQuantity"].active').each(function() {
            let chargerQuantity = $(this).val();
            let price = $(this).data('price');
            let totalChargersPrice = chargerQuantity * price;
            priceTotal = priceTotal + totalChargersPrice;
        });

        if (complements) {
            complements.each(function() {
                let complement = $(this);
                priceTotal = priceTotal + complement.data('price');
            });
        }
        if (crossSellerProducts) {
            crossSellerProducts.each(function() {
                let complement = $(this);
                priceTotal = priceTotal + complement.data('price');
            });
        }
        if (upSellingPersonalizedText.length) {
            priceTotal = priceTotal + parseFloat(upSellingPersonalizedText.data('price'));
        }

        if (priceTotal !== Math.floor(priceTotal)) {
            priceTotal = priceTotal.toFixed(2);
        }

        this.container.find('.btn-add-to-cart .btn-item-price').fadeOut('fast', function () {
            $(this).html(priceTotal).fadeIn().trigger('change');
        });
    };

    this.addToCart = function(crossSellingProducts) {
        if (getCookie('_tv_cookie_choice') === "") {
            blockedAllCookies();
            return;
        }

        let productId = this.container.find('#productId').val();
        let units = this.container.find('input[name="qty-display"]').val();
        let size = this.container.find('#productSize');
        let color = this.container.find('#color').val();
        let mirrored = this.container.find('input[name="orientation"]:checked').val() === 'reversed';
        let reversed = this.container.find('input[name="reversed"]').is(':checked');
        let personalizedText = this.container.find('#personalizedText').val();
        let personalizedImage = this.container.find('#personalizedImageRoute').val();
        let personalizedImageText = this.container.find('#personalizedImageText').val();
        let personalizedImageFromWeb = this.container.find('input[name="personalizedImageFromWeb"]').is(':checked');
        let complements = [];
        crossSellingProducts = crossSellingProducts || [];
        let upSellingProducts = [];
        let addCrossSelling = (typeof crossSellingProducts !== 'undefined' && crossSellingProducts.length > 0);

        let modelId = this.container.find('#model').val();
        let materialColorId = this.container.find('#materialColor').val();
        let modelSize = this.container.find('#modelSize').val();

       this.container.find('input[name="controlQuantity"].active').each(function() {
            let complement = $(this);
           let quantity = parseInt(complement.val());
           if (quantity) {
               complements.push(
                   {
                       'id': complement.data('id'),
                       'locked': complement.data('locked'),
                       'linkToParent': complement.data('link-to-parent'),
                       'quantity': quantity,
                       'options': {
                           'width': complement.data('width'),
                           'height': complement.data('height'),
                           'device': complement.data('device'),
                           'composition': complement.data('composition')
                       }
                   }
               );
           }
        });

        this.container.find('input[name="chargerQuantity"].active').each(function() {
            let complement = $(this);
            let quantity = parseInt(complement.val());
            if (quantity) {
                complements.push(
                    {
                        'id': complement.data('id'),
                        'locked': complement.data('locked'),
                        'linkToParent': complement.data('link-to-parent'),
                        'quantity': quantity,
                        'options': {
                            'width': complement.data('width'),
                            'height': complement.data('height'),
                            'device': complement.data('device'),
                            'composition': complement.data('composition')
                        }
                    }
                );
            }
        });

        this.container.find('input[name="complement"]:checked').each(function() {
            let complement = $(this);
            complements.push(
                {
                    'id': complement.data('id'),
                    'locked': complement.data('locked'),
                    'linkToParent': complement.data('link-to-parent'),
                    'options': {
                        'width': complement.data('width'),
                        'height': complement.data('height'),
                        'device': complement.data('device'),
                        'composition': complement.data('composition')
                    }
                }
            );
        });

        let upSellingPersonalizedText = this.container.find('input[name="upSellingPersonalizedText"]:checked');
        if (upSellingPersonalizedText.length) {
            upSellingProducts.push(
                {
                    'productId': upSellingPersonalizedText.data('id'),
                    'locked': upSellingPersonalizedText.data('locked'),
                    'quantity': upSellingPersonalizedText.data('quantity'),
                    'options': {
                        'isUpselling': true,
                        'width': upSellingPersonalizedText.data('width'),
                        'height': upSellingPersonalizedText.data('height'),
                        'text': upSellingPersonalizedText.data('text'),
                        'personalizedText': upSellingPersonalizedText.data('text'),
                        'personalizedFont': upSellingPersonalizedText.data('font'),
                        'color': upSellingPersonalizedText.data('color'),
                        'rightToLeft': upSellingPersonalizedText.data('right-to-left'),
                        'mirrored': upSellingPersonalizedText.data('mirrored') == 'reversed' ? 1 : 0,
                        'reversed': false,
                        'personalizedSvg': upSellingPersonalizedText.data('svg'),
                    }
                }
            );
        }

        let multiProducts = [];

        this.container.find(".multi-product-options").each(function () {
            let productIndex = $(this).data('productIndex').toString();
            multiProducts.push(
                {
                    index: productIndex,
                    options: {
                        text: $(this).find("#personalizedText"+productIndex).val(),
                        color: $(this).find('#color'+productIndex).val(),
                        personalizedText: $(this).find("#personalizedText"+productIndex).val(),
                        personalizedImage: $(this).find("#personalizedImageRoute"+productIndex).val(),
                        personalizedImageText: $(this).find("#personalizedImageText"+productIndex).val(),
                        personalizedImageFromWeb: $(this).find("input[name='personalizedImageFromWeb"+productIndex+"']").is(':checked'),
                        model: $(this).find("#model"+productIndex).val(),
                        materialColor: $(this).find("#materialColor"+productIndex).val(),
                        modelSize: $(this).find("#modelSize"+productIndex).val()
                    },
                }
            )
        })


        let width = null;
        let height = null;
        let device = null;
        let composition = null;
        let customWidth = this.container.find('#custom-width').val();
        let customHeight = this.container.find('#custom-height').val();
        let persWidth = this.container.find('#sizeWidthPers').val();
        let persHeight = this.container.find('#size-height-pers').html();
        let persWidthIsChecked = this.container.find('#rate-pers').is(':checked');
        let persWidthPopupIsChecked = this.container.find('#popup-rate-pers').is(':checked');
        let pack = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-pack"]:checked').val() : this.container.find('input[name="pack"]:checked').val();
        let effect = this.container.find('#effect').val();
        if (pack == 'custom') {
            pack = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-customPack"]').val() : this.container.find('input[name="customPack"]').val();
        }
        let convertUM = false;

        if (size.length) {
            device = size.data('device');
            width = size.data('width');
            height = size.data('height');
            composition = size.data('composition');
        }

        if (persWidthIsChecked || persWidthPopupIsChecked) {
            if (persWidth && persHeight) {
                width = persWidth;
                height = persHeight;
                convertUM = true;
            }
        }

        if (customWidth && customHeight) {
            width = customWidth;
            height = customHeight;
            convertUM = true;
        }

        if (modelId) {
            width = 1;
            height = 1;
        }

        if (units < 1) {
            units = 1;
        }

        let scope = this;

        // BLINDS OPTIONS
        let tissue = this.container.find('#tissue').val();
        let mechanism = this.container.find('#mechanism').val();
        let chainPosition = this.container.find('#chain-position').val();
        let drop = this.container.find('#drop').val();
        let referrer = null;
        if (document.referrer.indexOf(location.protocol + "//" + location.host) === 0) referrer = document.referrer;

        this.addToProductCartAjax(
            {
                productId: productId,
                quantity: units,
                options: {
                    width: width,
                    height: height,
                    device: device,
                    composition: composition,
                    text: personalizedText,
                    color: color,
                    mirrored: mirrored,
                    reversed: reversed,
                    personalizedText: personalizedText,
                    personalizedImage: personalizedImage,
                    personalizedImageText: personalizedImageText,
                    personalizedImageFromWeb: personalizedImageFromWeb,
                    model: modelId,
                    materialColor: materialColorId,
                    modelSize: modelSize,
                    pack: pack,
                    effect: effect,
                    tissue: tissue,
                    mechanism: mechanism,
                    chainPosition : chainPosition,
                    drop: drop,
                    referrer: referrer,
                },
                complements: complements,
                multiProducts: multiProducts,
                crossSellingProducts: crossSellingProducts,
                upSellingProducts: upSellingProducts,
                convertUM: convertUM
            },
            function (data) {
                scope.successAddToCart(data, true, addCrossSelling);
                /*if (upSellingPersonalizedText.length) {
                    upSellingPersonalizedText.trigger('resetUpsellingText');
                }*/
            },
            function (data) {
                scope.failureAddToCart(data, true, addCrossSelling);
            }
        );
    };

    this.successAddToCart = function(data, isAddToCart, isAddCrossSelling) {
        isAddCrossSelling = isAddCrossSelling || false;
        let scope = this;
        let addToCartEventType = isAddCrossSelling ? 'AddToCartCrossSelling' : 'AddToCart';
        let algoliaPrefix = scope.eventPage == 'ProductPage' ? 'Product' : 'List';
        let queryId = $('#al-query-id').val();
        if (data.cartItems) {
            let items = [];
            $.each(data.cartItems, function (index, item) {
                items.push(
                    {
                        item_id: item.reference,
                        item_name: item.name,
                        currency: item.currency,
                        price: item.price,
                        quantity: item.quantity
                    }
                );
                ecSendData(item.reference, item.name, item.price, item.quantity, item.options.colorApaCode, item.options.size, item.options.orientation, 'add', null);
                trackEvent(scope.eventPage, isAddToCart ? addToCartEventType : 'UpdateCart', item.name);
                if (window.fbq) {
                    var payload = {
                        content_type: 'product',
                        content_name: item.name,
                        content_ids: [item.reference],
                        contents: [{id: item.reference, quantity: item.quantity}],
                        value: item.totalPrice,
                        currency: item.currency
                    };
                    fbq('track', isAddToCart ? 'AddToCart' : 'UpdateCart', payload);
                    sendFacebookConversionsEvent(isAddToCart ? 'AddToCart' : 'UpdateCart', payload);
                }
                if (window.pintrk) {
                    pintrk('track', 'AddToCart', {
                        value: item.price,
                        order_quantity: item.quantity,
                        currency: item.currency
                    });
                }
                //reachGroupDE(item.id,item.price);
                productAddToCart(algoliaPrefix, 'Add To Cart', item.id.toString(), queryId);
            });
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    items: items
                }
            });
        }
        sendFloodLightEvent();
        hidePopup();
        showShoppingCart();
    };

    this.failureAddToCart = function(data, isAddToCart, isAddCrossSelling) {
        isAddCrossSelling = isAddCrossSelling || false;
        let addToCartEventType = isAddCrossSelling ? 'AddToCartCrossSelling' : 'AddToCart';
        trackEvent(this.eventPage, isAddToCart ? addToCartEventType : 'UpdateCart', 'error');
        let panel;
        switch (data.errorCode) {
            case 'size':
                panel = this.container.find('#add-to-cart-size');
                break;
            case 'color':
                panel = this.container.find('#add-to-cart-color');
                break;
            case 'orientation':
                panel = this.container.find('#add-to-cart-other');
                break;
            case 'tissue':
                panel = this.container.find('#tissue-option');
                break;
            case 'mechanism':
                panel = this.container.find('#mechanism-option');
                break;
            case 'drop':
                panel = this.container.find('#drop-option');
                break;
            default:
                panel = this.container.find('#add-to-cart-custom');
                break;
        }
        if (panel.find('.panel-dropdown-body').length) {
            if (panel.find('.panel-dropdown-body').is(':hidden')) {
                panel.find('.panel-dropdown-header').trigger('click');
            }
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: panel.offset().top - 150
                }, 500);
            }, 1000);
        } else {
            if (!panel.find('.slider-section-title').hasClass('expanded')) {
                panel.find('.slider-section-title').trigger('click');
            }
        }
        popupInfoDialog(data.errorMessage);
    };


    // TOCAR AQUI!
    this.updateCartItem = function() {
        let itemId = this.container.find('#cartItemId').val();
        let productId = this.container.find('#productId').val();
        let productName = this.container.find('#selectedProductName').text();
        let units = this.container.find('input[name="qty-display"]').val();
        let size = this.container.find('#add-to-cart-size #productSize');
        let text = this.container.find('#personalizedText').val();
        let color = this.container.find('#color').val();
        let mirrored = this.container.find('input[name="orientation"]:checked').val() === 'reversed';
        let reversed = this.container.find('input[name="reversed"]').is(':checked');
        let materialCanvas = this.container.find('input[name="material-canvas"]:checked').val();
        let personalizedText = this.container.find('#personalizedText').val();
        let personalizedImage = this.container.find('#personalizedImageRoute').val();
        let personalizedImageText = this.container.find('#personalizedImageText').val();
        let personalizedImageFromWeb = this.container.find('input[name="personalizedImageFromWeb"]').is(':checked');
        let customPriceIncrement = this.container.find('input[name="customPriceIncrement"]')
            && this.container.find('input[name="customPriceIncrement"]').is(':checked') ? true : null;

        let modelId = this.container.find('#model').val();
        let materialColorId = this.container.find('#materialColor').val();
        let modelSize = this.container.find('#modelSize').val();

        let width = null;
        let height = null;
        let device = null;
        let composition = null;
        let customWidth = this.container.find('#custom-width').val();
        let customHeight = this.container.find('#custom-height').val();
        let persWidth = this.container.find('#sizeWidthPers').val();
        let persHeight = this.container.find('#size-height-pers').html();
        let persWidthIsChecked = this.container.find('#rate-pers').is(':checked');
        let persWidthPopupIsChecked = this.container.find('#popup-rate-pers').is(':checked');
        let pack = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-pack"]:checked').val() : this.container.find('input[name="pack"]:checked').val();
        let effect = this.container.find('#add-to-cart-effect #effect').val();
        if (pack == 'custom') {
            pack = this.container.hasClass('product-popup-options') ? this.container.find('input[name="add-to-cart-customPack"]').val() : this.container.find('input[name="customPack"]').val();
        }
        let convertUM = false;

        let complements = [];

        // BLINDS OPTIONS
        let tissue = this.container.find('.modify-product #tissue').val();
        let mechanism = this.container.find('.modify-product #mechanism').val();
        let chainPosition = this.container.find('.modify-product #chain-position').val();
        let drop = this.container.find('.modify-product #drop').val();
        let supportColor = this.container.find('.modify-product #support-color').val();

        //VINYL RUG OPTIONS
        let format = this.container.find('.modify-product input[name="vinyl-rug-format"]:checked, input[name="vinyl-rug-format"][type="hidden"]').val();

        this.container.find('.modify-product input[name="controlQuantity"].active').each(function() {
            let complement = $(this);
            complements.push(
                {
                    'id': complement.data('id'),
                    'locked': complement.data('locked'),
                    'linkToParent': complement.data('link-to-parent'),
                    'quantity': complement.val(),
                    'options': {'size': complement.data('size-id')}
                }
            );
        });

        this.container.find('.modify-product input[name="chargerQuantity"].active').each(function() {
            let complement = $(this);
            complements.push(
                {
                    'id': complement.data('id'),
                    'locked': complement.data('locked'),
                    'linkToParent': complement.data('link-to-parent'),
                    'quantity': complement.val(),
                    'options': {'size': complement.data('size-id')}
                }
            );
        });

        if (size.length) {
            device = size.data('device');
            width = size.data('width');
            height = size.data('height');
            composition = size.data('composition');
        }

        if (persWidthIsChecked || persWidthPopupIsChecked) {
            if (persWidth && persHeight) {
                width = persWidth;
                height = persHeight;
                convertUM = true;
            }
        }

        if (customWidth && customHeight) {
            width = customWidth;
            height = customHeight;
            convertUM = true;
        }

        if (modelId) {
            width = 1;
            height = 1;
        }

        if (units < 1) {
            units = 1;
        }

        let multiProducts = [];

        this.container.find(".multi-product-options").each(function () {
            let productIndex = $(this).data('productIndex').toString();
            multiProducts.push(
                {
                    index: productIndex,
                    options: {
                        text: $(this).find("#personalizedText"+productIndex).val(),
                        color: $(this).find('#color'+productIndex).val(),
                        personalizedText: $(this).find("#personalizedText"+productIndex).val(),
                        personalizedImage: $(this).find("#personalizedImageRoute"+productIndex).val(),
                        personalizedImageText: $(this).find("#personalizedImageText"+productIndex).val(),
                        personalizedImageFromWeb: $(this).find("input[name='personalizedImageFromWeb"+productIndex+"']").is(':checked'),
                        model: $(this).find("#model"+productIndex).val(),
                        materialColor: $(this).find("#materialColor"+productIndex).val(),
                        modelSize: $(this).find("#modelSize"+productIndex).val()
                    },
                }
            )
        })

        let scope = this;
        this.updateProductCartItemAjax(
            {
                id: itemId,
                productId: productId,
                productName: productName,
                quantity: units,
                options: {
                    width: width,
                    height: height,
                    device: device,
                    composition: composition,
                    text: text,
                    color: color,
                    mirrored: mirrored,
                    reversed: reversed,
                    personalizedText: personalizedText,
                    personalizedImage: personalizedImage,
                    personalizedImageText: personalizedImageText,
                    personalizedImageFromWeb: personalizedImageFromWeb,
                    customPriceIncrement: customPriceIncrement,
                    model: modelId,
                    materialColor: materialColorId,
                    modelSize: modelSize,
                    pack: pack,
                    effect: effect,
                    tissue: tissue,
                    mechanism: mechanism,
                    chainPosition : chainPosition,
                    drop: drop,
                    supportColor: supportColor,
                    materialCanvas: materialCanvas,
                    format: format
                },
                multiProducts: multiProducts,
                complements: complements,
                convertUM: convertUM
            },
            function(data){
                scope.successAddToCart(data, false);
            },
            function(data){
                scope.failureAddToCart(data, false);
            }
        );
    };

    this.addToProductCartAjax = function(data, success, error, loader = true) {
        if (loader) waitingDialog.show();
        $.ajax({
            type: 'POST',
            url: '/ajax/shopping/add-to-cart',
            data: data,
            success: function (data) {
                if (loader) waitingDialog.hide();
                if (data.done) {
                    typeof success === 'function' && success(data);
                } else {
                    typeof error === 'function' && error(data);
                }
            },
            error: function (data) {
                if (loader) waitingDialog.hide();
                typeof error === 'function' && error(data);
            }
        });
    };

    this.updateProductCartItemAjax = function(data, success, error) {
        waitingDialog.show();
        $.ajax({
            type: 'POST',
            url: '/ajax/shopping/modify-cart-item',
            data: data,
            success: function (data) {
                waitingDialog.hide();
                if (data.done) {
                    typeof success === 'function' && success(data);
                } else {
                    typeof error === 'function' && error(data);
                }
            },
            error: function (data) {
                waitingDialog.hide();
                typeof error === 'function' && error(data);
            }
        });
    };

    this.reachGroupDE = function(productId,qty) {
        /*
         * Insert your items or products of productfeed #12850 (Dummy) in the parameter "tp_rtrgt_items".
         * This is a single-productfeed-tag, so you do not need to specify the productfeed-id individually for every item or product you pass.
         *   a) Without passing the product-quantity: ID,ID,...
         *   b) For better retargeting-results pass the product-quantity: ID|QUANTITY,ID|QUANTITY,...
         */

        let tp_rtrgt_items = productId + '|' + qty;

        let tp_rtrgt_segment = '';

        /* DO NOT change the javascript below */
        let tp_rtrgt_random = Math.random() * 10000000000000000;
        let tp_rtrgt_url = 'http'+(('https:'==document.location.protocol)?'s':'')+'://hal9000.redintelligence.net/retarget?a=44954&version=1&event=basket&cat=12850';
        tp_rtrgt_url += '&segment=' + tp_rtrgt_segment;
        tp_rtrgt_url += '&items=' + tp_rtrgt_items;
        let tp_rtrgt_iframe = 'i' + tp_rtrgt_random;
        window.tp_rtrgt_iframes = window.tp_rtrgt_iframes||[];
        window.tp_rtrgt_iframes[tp_rtrgt_iframe] = document.createElement('iframe');
        window.tp_rtrgt_iframes[tp_rtrgt_iframe].height = "1";
        window.tp_rtrgt_iframes[tp_rtrgt_iframe].width = "1";
        window.tp_rtrgt_iframes[tp_rtrgt_iframe].scrolling = "no";
        window.tp_rtrgt_iframes[tp_rtrgt_iframe].cssText = "position:absolute;visibility:hidden;left:0px;top:0px;width:1px;height:1px;border:0";

        window.tp_rtrgt_iframes[tp_rtrgt_iframe].src = tp_rtrgt_url;
        let tp_rtrgt_first_script = document.getElementsByTagName('script')[0];
        tp_rtrgt_first_script.parentNode.appendChild( window.tp_rtrgt_iframes[tp_rtrgt_iframe] );
    };

    this.validateQuantityInput = function(input,quantity) {
        quantity = +quantity || 1; //make sure it's a number
        quantity = Math.max(quantity, 1);
        let btnRemove = input.siblings('.qty-btn-remove');
        if (quantity === 1) {
            btnRemove.attr('disabled', true);
        } else {
            btnRemove.attr('disabled', false);
        }
        input.val(quantity).trigger('change');
    };

    this.displayPersonalizedFields = function() {
        let personalizedTextField = this.container.find('#personalizedText'),
            personalizedImageField = this.container.find('#personalizedImageRoute');
        if (personalizedTextField.length) {
            this.container.find('#sp-txt').html(personalizedTextField.val()).show();
        }
        if (personalizedImageField.length && personalizedImageField.val()) {
            this.container.find('#sp-file').show();
        } else {
            this.container.find('#sp-file').hide();
        }
    };

    this.resetPersonalizedFields = function() {
        this.container.find('#personalizedImage').val(null);
        this.container.find('#personalizedImageRoute').val('');
        this.container.find('input[name="personalizedImageFromWeb"]').prop('checked', false);
        this.container.find('#personalizedImageText').val('');
        this.container.find('#personalizedText').val('');
        this.displayPersonalizedFields();
    };

    $( document ).ajaxSend(function(event, jqxhr, settings) {
        if ( settings.url == "/ajax/recommendations/product-score" ) {
            window.isAjaxRunning = false;
        }
    })

    return this;
}

export function roundToMultiple(multiple, input)
{
    let alertElement = $('#wrong-size-alert-multiple');
    alertElement.hide();
    multiple = parseFloat(multiple);
    let roundToNearest = x => Math.ceil(x/multiple)*multiple;
    let value = Number(input.val());
    let newValue = parseFloat(roundToNearest(value).toFixed(2));
    let onlyOneSide = input.data('onlyOneSide');

    if (newValue !== value && newValue > 0) {
        input.val(newValue).trigger('change');
        let message = '';
        if (onlyOneSide) {
            message = alertElement.data('text').replace('XX', newValue);
        } else {
            let width = $("#widthPersonalized").val() !== '' ? $("#widthPersonalized").val() : '-';
            let height = $("#heightPersonalized").val() !== '' ? $("#heightPersonalized").val() : '-';
            message = alertElement.data('text').replace('XXcm x XXcm', width + 'cm x ' + height + 'cm');
        }
        alertElement.text(message).show()
    }
}

export function roundToMultipleOfFifty() {
    const roundToNearest50 = x => Math.round(x/50)*50;
    const inchesToCentimetersAndBack = x => Math.round(roundToNearest50(x * 2.54)) / 2.54;
    const roundToOne = x => +(Math.round(x + "e+1")  + "e-1");
    let value = Number($('.roundToMultipleOfFifty').val()),
        newValue;
    if ($('.roundToMultipleOfFifty').hasClass('convertInches')) {
        newValue = roundToOne(inchesToCentimetersAndBack(value));
    } else {
        newValue = roundToNearest50(value);
    }

    if (newValue !== value) {
        $('.roundToMultipleOfFifty').val(newValue);
        let width = !$('#width').hasClass('empty') ? $('#width').val() : '-';
        let textPhotoMural = $('#wrong-size-alert-min-width').data('text').replace('XX', width);

        $('#minWidth50').hide();
        $("#alertMeasuresPhotoMural").slideDown('fast');
        $("#errorMeasuresPhotoMural").slideDown('fast');
        $('#wrong-size-alert-min-width').text(textPhotoMural).show()
    }
}

export function roundToMultipleOfFive() {
    const roundToNearest5 = x => Math.round(x/5)*5;
    const inchesToCentimetersAndBack = x => Math.round(roundToNearest5(x * 2.54)) / 2.54;
    const roundToOne = x => +(Math.round(x + "e+1")  + "e-1");
    $(document).on('change', '.roundToMultipleOfFive', function() {
        let value = Number($(this).val()),
            newValue;
        if ($(this).hasClass('convertInches')) {
            newValue = roundToOne(inchesToCentimetersAndBack(value));
        } else {
            newValue = roundToNearest5(value);
        }
        if (newValue !== value) {
            $(this).val(newValue);
            let width = !$('#custom-width').hasClass('empty') ? $('#custom-width').val() : '-';
            let height = !$('#custom-height').hasClass('empty') ? $('#custom-height').val() : '-';
            let text = $('#multiple-of-five-alert').data('text').replace('XXcm x XXcm', width + 'cm x ' + height + 'cm');
            $('#multiple-of-five-alert').text(text).show();
        }
    });
}